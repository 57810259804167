var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('v-overlay',{attrs:{"value":_vm.verifying || _vm.waiting}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),(_vm.verifySuccess)?_c('v-container',[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',{staticClass:"headline text-uppercase"},[_c('span',[_c('a',{attrs:{"href":"/"}},[_c('v-img',{attrs:{"max-height":"60","max-width":"240","src":require("../../assets/logos/AuditEngineLogo.png")}})],1)]),_c('span',{staticClass:"font-weight-light"})])],1),_c('v-container',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$refs.files.click()}}},[_c('input',{ref:"files",staticStyle:{"display":"none"},attrs:{"id":"fileSelector","type":"file","multiple":""},on:{"change":_vm.filesSelected}}),_c('span',{staticClass:"mr-2"},[_vm._v("Select Files")])])],1),_c('v-card',[_c('v-card-title'),_c('v-card-text',[(_vm.is_general == 1)?_c('v-row',[_c('v-col',{staticClass:"g-layout",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Your Name","required":"","rules":[_vm.rules.required],"validate-on-blur":"","outlined":""},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})],1),_c('v-col',{staticClass:"g-layout",attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Your Email","outlined":"","required":"","rules":[_vm.rules.required, _vm.rules.email],"validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.files,"loading":_vm.uploadedFilesLoading},scopedSlots:_vm._u([{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":item.progress,"height":"25"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(item.progress))+"%")])])]}},{key:"item.byterate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((8 * Math.round((10 * item.byterate) / 1024 / 1024)) / 10)+"Mbps ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.success)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v("Success")]):(item.status.failed)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v("Failed")]):(item.status.canceled)?_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white"}},[_vm._v("Canceled")]):(item.status.uploading)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","text-color":"white"}},[_vm._v("Uploading")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","text-color":"white"}},[_vm._v("Ready")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status.uploading)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.cancelFileUpload(item)}}},[_c('v-icon',[_vm._v("mdi-stop")])],1):(!item.status.success)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.uploadFile(item)}}},[_c('v-icon',[_vm._v("mdi-upload")])],1):_vm._e(),(!item.status.uploading)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.removeFile(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()]}}],null,false,2483963532)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.files.length == 0},on:{"click":_vm.uploadFiles}},[_vm._v("Upload All")]),_c('v-btn',{attrs:{"disabled":_vm.files.length == 0},on:{"click":_vm.clearFiles}},[_vm._v("Remove All")])],1)],1)],1)],1):(!_vm.verifying)?_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('a',{attrs:{"href":"/"}},[_c('v-img',{attrs:{"max-height":"350","max-width":"550","src":require("../../assets/logos/AuditEngineLogo.png")}})],1)]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('h3',[_vm._v("Opps! We are sorry.")])])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }